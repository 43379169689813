<template>
  <b-row>
    <b-col>
      <div class="d-flex align-items-center">
        <icon-move-vertical class="card-content__transparent mr-1" />
        <div
          v-if="isEditMode"
          v-click-outside="onClickOutside"
          class="w-100"
          @click.stop
        >
          <validation-observer ref="vo">
            <validation-provider
              #default="{ errors }"
              name="category_name"
              rules="required"
            >
              <b-form-input
                v-model="name"
                :placeholder="$t('Category Name')"
                :state="errors.length ? false : null"
              />
            </validation-provider>
          </validation-observer>
        </div>
        <div
          v-else
          :class="['d-flex align-items-center flex-grow-1', { 'cursor-pointer': isContentValid }]"
        >
          <div
            class="h5 mb-0"
            @click.stop="onClickEdit"
          >
            {{ name }}
            <feather-icon
              v-b-tooltip.hover.top="$t('Edit')"
              :class="['align-bottom card-content__transparent', { 'feather-disabled': !isContentValid }]"
              icon="Edit3Icon"
              role="button"
              size="20"
            />
          </div>
        </div>
        <div class="card-content__arrow ml-1">
          <div
            :class="[
              'card-content__arrow',
              { 'collapsed': isCategoryCollapsed },
            ]"
          >
            <feather-icon
              size="20"
              icon="ChevronDownIcon"
            />
          </div>
        </div>
      </div>
    </b-col>
    <b-col
      cols="auto"
      class="d-flex align-items-center"
    >
      <feather-icon
        :class="['card-content__transparent', { 'feather-disabled': !isContentValid }]"
        role="button"
        size="20"
        icon="TrashIcon"
        @click.stop="$emit('delete')"
      />
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  VBTooltip,
} from 'bootstrap-vue'
import IconMoveVertical from '@/components/icon/IconMoveVertical.vue'
import directiveClickOutside from '@/directives/clickOutside'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'

export default {
  name: 'CategoryName',
  directives: {
    'b-tooltip': VBTooltip,
    clickOutside: directiveClickOutside,
  },
  components: {
    BRow,
    BCol,
    BFormInput,
    IconMoveVertical,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    categoryId: {
      type: String,
      required: true,
      default: '',
    },
    categoryName: {
      type: String,
      required: true,
      default: '',
    },
    isContentValid: {
      type: Boolean,
      required: true,
      default: true,
    },
  },
  data() {
    return {
      isEditMode: false,
      name: '',
      isCategoryCollapsed: true,

      // validation rules
      required,
    }
  },
  watch: {
    categoryName: {
      handler(newValue) {
        this.name = newValue
      },
      immediate: true,
    },
  },
  mounted() {
    this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
      // console.log('collapseId:', collapseId, '| isJustShown:', isJustShown)
      if (collapseId === `${this.categoryId}_content`) {
        this.isCategoryCollapsed = !isJustShown
      }
    })
  },
  methods: {
    async onClickOutside() {
      if (this.name === this.categoryName) {
        this.isEditMode = false
        return
      }

      const isValid = await this.$refs.vo.validate()
      if (!isValid) {
        return
      }

      this.isEditMode = false
      this.$emit('patchCategoryName', this.name)
    },
    onClickEdit() {
      if (!this.isContentValid) {
        return
      }

      setTimeout(() => {
        this.isEditMode = true
      })
    },
  },
}
</script>
